.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8);
    z-index: 1; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 80px; /* Location of the content container */
}

.modal-content {
    background-color: white;
    position: relative;
    /* height: 70%; */
    padding: 10px;
    margin: auto;
    border: 1px solid black;
    border-radius: 20px; /* Optional. Rounds container corners */
}

.close {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 2em;
}

.close:hover {
    cursor: pointer;
    color: darkgrey;
}
