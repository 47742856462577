@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@400;700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.10.2/css/all.css');
html {
    box-sizing: border-box;
    margin: 0;
    font-size: 16px;
    height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    font-family: Lato, sans-serif;
    height: 100%;
    background: #FFF3CB;
}

main {
    padding-bottom: 55px;
}

header {
    background: orange;
    max-height: 50px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 5px 6px -5px #9D5923;
    box-shadow: 0px 5px 6px -5px #9D5923;
    transition: 0.3s max-height ease;
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    background: orange;
    -webkit-box-shadow: 0px -5px 6px -5px #9D5923;
    box-shadow: 0px -5px 6px -5px #9D5923;
}

footer p {
    margin: 10px 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat';
    padding: 1rem 0;
    margin: 0;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

nav ul {
    list-style: none;
}

nav ul>li {
    display: inline;
    padding: 0 10px;
}

nav ul li.mobile-only {
    display: none;
}

nav ul>li>a {
    text-decoration: none;
    color: whitesmoke;
    font-weight: 600;
    letter-spacing: 0.4px;
}



a.active {
    color: #7A766F;
    cursor: text;
}

.container {
    margin: 0 auto;
}

.container-100 {
    max-width: 100%;
}

.container-90 {
    max-width: 90%;
}

.container-80 {
    max-width: 80%;
}

.container-70 {
    max-width: 70%;
}

.container-60 {
    max-width: 60%;
}

.container-50 {
    max-width: 50%;
}

.responsive-container {
    max-width: 50%;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
}

.flex-column-container {
    display: flex;
    flex-flow: column wrap;
}

.flex-center {
    justify-content: center;
}

.flex-between {
    justify-content: space-between;
}

.flex-around {
    justify-content: space-around;
}

.flex-end {
    justify-content: flex-end;
}

.flex-stretch {
    align-items: stretch;
}

.logo {
    max-width: 120px;
    width: 100%;
}

.navbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.centered {
    text-align: center;
}

.card-container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    /* justify-content: space-between; */
}

.card {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    background: white;
    /* border: 1px solid orangered; */
    margin: 10px;
    min-width: 163px;
    -webkit-box-shadow: 0px 0px 9px 1px #442D17;
    box-shadow: 0px 0px 9px 1px #442D17;
}

.card:hover {
    background: linen;
    -webkit-box-shadow: 0px 0px 5px 1px #442D17;
    box-shadow: 0px 0px 5px 1px #442D17;
}

.flex-100 {
    flex-basis: 100%;
}

.flex-50 {
    flex-basis: 48%;
}

.flex-30 {
    flex-basis: 32%;
}

.flex-20 {
    flex-basis: 23%;
}

.card-header {
    text-align: center;
}

.card-body {
    padding: 20px 30px;
}

.card-footer {
    /* min-height: 100px; */
    width: 100%;
    padding-bottom: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.white {
    color: white;
}

.button-no-action {
    border: none;
    border-radius: 1rem;
    color: white;
    background: darkgray;
    padding: 5px 10px;
}

.button-oval {
    border: none;
    border-radius: 1rem;
    color: white;
    font-weight: 700;
    background: rgba(100, 100, 100, 0.7);
    padding: 10px 30px;
    cursor: pointer;
    font-size: 1.2rem;
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.51);
    transition: background 0.15s ease-in;
}

.button-oval:hover {
    background: rgba(100, 100, 100, 1);
}

.button-square {
    border: none;
    border-radius: 0.3em;
    color: white;
    padding: 5px 20px;
    margin: 5px;
    background: coral;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 6px 1px coral;
    box-shadow: 0px 0px 6px 1px coral;
    transition: all 0.15s ease-in;
}

.button-square:hover {
    background: orangered;
}

.button-square:disabled {
    background: darkgray;
    -webkit-box-shadow: none;
    box-shadow: none;
}

select {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

select::after {
    position: absolute;
    content: 'v';
    width: 5px;
    right: 0;
    top: 5px;
    color: white;
}

.form-wrapper form {
    min-height: 250px;
    border: 1px solid orange;
    border-radius: 12px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.form-wrapper form label {
    display: block;
}

.form-wrapper form input.invalid {
    -webkit-box-shadow: 0px 0px 3px 3px rgba(255, 0, 0, 0.92);
    box-shadow: 0px 0px 3px 3px rgba(255, 0, 0, 0.92);
}

.form-field {
    position: relative;
    margin-top: 1.6rem;
}

.form-field .validation-error {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}

input:not([type="submit"]) {
    border: none;
    border-radius: 2px;
    height: 26px;
    /* min-width: 150px; */
    -webkit-box-shadow: 2px 2px 6px 1px #000000;
    box-shadow: 2px 2px 6px 1px #000000;
    padding-left: 11px;
    margin-right: 8px;
}

.form-field input {
    width: 155px;
}

.form-field input[type="submit"] {
    margin: 10px 0;
    /* min-width: 155px; */
}

@media screen and (max-width:768px) {
    .responsive-container {
        max-width: 90%;
    }
    main {
        padding-bottom: 105px;
    }
    footer {
        height: 100px;
    }
}

@media screen and (max-width: 540px) {
    .responsive-container .button-square {
        flex-basis: 45%;
        padding: 10px 30px;
        margin: 10px 0;
    }
    .responsive-container .button-square.order-last {
        flex-basis: 100%;
        order: 10;
    }
    .card-container {
        justify-content: center;
    }
    .card {
        flex-basis: 90%;
    }
    header.show {
        max-height: 400px;
        /* min-height: fit-content; */
    }
    header.show nav ul li {
        opacity: 1;
        visibility: visible;
        transition: 0.3s opacity ease-in;
    }
    nav ul {
        text-align: end;
        margin: 0;
    }
    nav ul li.mobile-only {
        display: block;
        color: whitesmoke;
        min-height: 16px;
        margin: 17px 0;
        /* margin-bottom: 10px; */
    }
    nav ul li:not(.mobile-only) {
        display: block;
        visibility: hidden;
        opacity: 0;
        padding: 8px 0;
        transition: 0s opacity;
        /* border-bottom: 1px solid whitesmoke; */
    }
}